/*
 * *************************************************************************
 *
 * Copyright: Robert Bosch GmbH, 2020
 *
 * *************************************************************************
 */

import * as svg4everybody from 'svg4everybody';

svg4everybody();
